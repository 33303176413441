<template>
<CContainer>
   <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
           <CRow class="">
             <CCol col="3">
               <router-link :to="'/noticias'">
                  <CButton color="success">
                      Atras
                  </CButton>
                </router-link>
              </CCol>
              <CCol col="6">
                <h5 class="text-center">Crear Noticia</h5>
              </CCol>
              <CCol col="3">
                  <CButton color="primary" @click="agregarNuevaFila()">
                      Agregar Fila
                  </CButton>
              </CCol>
           </CRow>
        </CCardHeader>
        <CCardBody>
           <CRow>
              <CCol col="4">
                <CInput
                    label="Titulo Noticia"
                    placeholder="Titulo Noticia"
                    autocomplete="off"
                    minlength="3"
                    v-model="form_noticia.titulo"
                    />
              </CCol>
              <CCol col="6">
                <CRow>
                    <CCol col="6">
                      <CInput
                        label="Fecha Inicio"
                        placeholder="Fecha Inicio"
                        autocomplete="off"
                        type="date"
                        :min="fechaHoy"
                        @change="obtenerfechainicio"
                        v-model="form_noticia.fecha_inicio"
                      />
                    </CCol>
                    <CCol col="6">
                      <CInput
                        label="Fecha Fin"
                        placeholder="Fecha Fin"
                        autocomplete="off"
                        type="date"
                        :min="form_noticia.fecha_inicio"
                        v-model="form_noticia.fecha_fin"
                      />
                    </CCol>
                 </CRow>
              </CCol>
              <CCol col="2">
                  <CInput
                    label="Visualizaciones"
                    placeholder="3"
                    maxlength="2"
                    autocomplete="off"
                    @keypress="isNumber($event)"
                    v-model="form_noticia.visualizaciones"
                    />
              </CCol>
           </CRow>
           <hr>
           <CRow>
             <CCol col="12">
                 <CRow v-for="(item, index) in noticias" v-bind:key="index">
                      <CCol col="5">
                        <label for="">Comentario de la noticia</label>
                        <textarea class="form-control" v-model="item.comentario"></textarea>
                      </CCol>
                      <CCol col="5">
                        <center>
                          <img :src="item.urlpreview" v-if="item.urlpreview != null" alt="" align="center" width="100px" height="100px" />
                        </center>
                      </CCol>
                      <CCol col="2">
                        <CRow>
                            <CCol col="12">
                                <input type="file" :id="'file_'+index" style="display: none" @change="cargarArchivoEnvio($event,index)">
                              <CButton color="success" size="sm" variant="outline" @click="abrirExplorador(index)" >Cargar Imagen</CButton> 
                            </CCol>
                            <hr>
                            <CCol col="12">
                              <CButton color="danger" size="sm" variant="outline" @click="eliminarFila(index)">Eliminar Fila</CButton> 
                            </CCol>
                        </CRow>
                      </CCol>
                 </CRow>
              </CCol>
           </CRow>
           <hr>
           <CRow>
              <CCol col="12">
                 <CButton color="primary" variant="outline" @click="crearnNoticiasRequest"> Crear Noticia </CButton> 
              </CCol>
           </CRow>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
  <div>
      <CToaster :autohide="5000" class="d-inline-block">
             <template v-for="toast in mostrarNotificacion">
                <CToast
                  :key="'toast'+toast"
                  :show="true"
                  color="success"
                  header="Notificacion Exitosa"
                >
                  {{mensaje_success}}
                </CToast>
              </template>   
              <template v-for="toast1 in mostrarNotificacionAlerta">
                <CToast
                  :key="'toast1'+toast1"
                  :show="true"
                  color="warning"
                  header="Notificacion Alerta"
                >
                  {{mensaje_warning}}
                </CToast>
              </template>
              <template v-for="toast2 in mostrarNotificacionError">
                <CToast
                  :key="'toast2'+toast2"
                  :show="true"
                  color="danger"
                  header="Notificacion Alerta"
                >
                  Error Comunicarse Con Soporte
                </CToast>
              </template>  
          </CToaster>    
  </div>
</CContainer>

</template>


<script>
export default {
  name: 'CrearNoticia',
  components: { },
  data () {
    return {
      mostrarNotificacion: 0,
      mostrarNotificacionAlerta: 0,
      mostrarNotificacionError:0,
      mensaje_warning:'Debe Completar Los Datos Vacio',
      mensaje_success:'Noticia Creada Correctamente.',
      form_noticia:{
        titulo:null,
        fecha_inicio:null,
        fecha_fin:null,
        visualizaciones:1,
      },
      noticias:[
        {
          urlpreview:null,
          archivo:null,
          comentario:null,
        }
      ],
      fechaHoy:null,
      fechaMax:null,
    }
  },
  methods: {
    agregarNuevaFila(){
      this.noticias.push(
        {
          urlpreview:null,
          archivo:null,
          comentario:null,
        }
      )
    },
    eliminarFila(index){
       this.noticias.splice(index,1);
    },
    cargarArchivoEnvio(event,index) {
       let files = event.target.files;
        this.noticias[index].archivo = files[0]
        if (this.noticias[index].archivo.name != undefined) {
            let extension = this.getFileExtension2(this.noticias[index].archivo.name)
            if (extension == 'png' || extension == 'jpg' || extension == 'jpeg'){
                //console.log(this.archivo_envio);
                this.noticias[index].urlpreview = this.previewImagen(this.noticias[index].archivo);
            }else{
                this.fileInputKey++;
                this.noticias[index].archivo = null;
                this.noticias[index].urlpreview = null
                this.mostrarNotificacionAlerta ++; 
                this.mensaje_warning='Solo se Permite Archivos PNG , JPG y JPEG'
            }
        }
      },
      abrirExplorador(index){
        document.getElementById('file_'+index).click();
      },
      getFileExtension2(filename) {
        return (filename.split('.').pop()).toLowerCase();
      },
      previewImagen(file){
        return URL.createObjectURL(file);
      },
      validarNoticiaItem(){
        let array = this.noticias;
        let contador = 0;
        for (const iterator of array) {
           if (iterator.archivo == null) {
              contador ++;
           }
        }
        return contador;
      },
      validarNoticia(){
        let titulo = this.form_noticia.titulo == null ? "" :  this.form_noticia.titulo;
        if ((titulo).trim() == "" || this.form_noticia.visualizaciones == 0) {
          return 1;
        }
        return 0;
      },
      crearnNoticiasRequest(){
        if (this.validarNoticiaItem() > 0) {
            this.mostrarNotificacionAlerta ++; 
            this.mensaje_warning='Para guardar debe seleccionar una imagen'
        }else if(this.validarNoticia() > 0){
           this.mostrarNotificacionAlerta ++; 
            this.mensaje_warning='Para guardar debe completar toda la informacion'
        }else{
          let InstFormData = new FormData();
          InstFormData.append('noticia',JSON.stringify(this.form_noticia)); 
          InstFormData.append('cantidad',this.noticias.length); 
          let contador = 0;
          for (const iterator of this.noticias) {
            InstFormData.append('comentario_'+contador,iterator.comentario); 
            InstFormData.append('archivo_'+contador,iterator.archivo); 
            contador++;
          }

          this.$store.dispatch('auth/loading_http','visible');
          this.$http.post('/sms/noticias',InstFormData,{
            headers : {'content-type': 'multipart/form-data'}
          }).then(response => {
                if (response.data == "OK") {
                  this.mostrarNotificacion ++; 
                  this.mensaje_success = 'Noticia Creada Correctamente.'
                }
          }).catch(()=>{
              this.mostrarNotificacionError++;
          }).finally(() =>{
                  this.$store.dispatch('auth/loading_http','hidden')
                  this.$store.dispatch('auth/loading_http','visible');
                  setTimeout(() => {
                  this.$store.dispatch('auth/loading_http','hidden')   
                  this.goBack();      
                  }, 5000);
          });
        }
      },
      goBack() {
        this.usersOpened ? this.$router.go(-1) : this.$router.push({path: '/noticias'})
      },
      calcTime(city, offset) {
        let d = new Date();
        let utc = d.getTime() + (d.getTimezoneOffset() * 60000);
        let nd = new Date(utc + (3600000*offset));
        return nd.toLocaleString();
      },
      fechaActual(fechaCompara){
        let hoy = this.calcTime('Bogota', '-5');
        hoy = hoy.split(",")[0];
        hoy = hoy.split("/");
        hoy = `${hoy[2]}-${hoy[1] < 10 ? '0'+(hoy[1]): hoy[1]}-${hoy[0] < 10 ? '0'+hoy[0] : hoy[0]}`
        return hoy;
      },
      obtenerfechainicio(){
        this.form_noticia.fecha_fin = this.form_noticia.fecha_inicio
      },
      isNumber: function(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
        //if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
          evt.preventDefault();;
        } else {
          return true;
        }
      },
  },
  mounted(){
    this.fechaHoy = this.fechaActual();
    this.form_noticia.fecha_inicio = this.fechaActual();
    this.form_noticia.fecha_fin = this.fechaActual();
    setTimeout(() => {
      
    }, 100);
  },
  computed: {
    
  }
}
</script>
